import React from "react";

const Dashboard = () => {

  return (
    <>
      Hi, I&apos;m Astro... wanna play tug of war?
    </>
  );
};

export default Dashboard;
